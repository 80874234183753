import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';

import { readAllProducts } from "../functions/products"

function PrintToPdf() {
    const products = readAllProducts();
    const styles = StyleSheet.create({
        page: {
            flexDirection: 'column',
            paddingVertical: "20mm",
            paddingHorizontal: "10mm"
        },
        heading: {
            fontSize: 30,
            margin: "auto",
        },
        table: { 
            display: "table",
            width: "auto", 
            borderStyle: "solid", 
            borderWidth: 0, 
            borderRightWidth: 0, 
            borderBottomWidth: 0
        }, 
        tableRow: { 
            flexDirection: "row"
        }, 
        tableCol1: { 
            width: "20%", 
            borderStyle: "solid", 
            borderWidth: 0
        },
        tableCol2: { 
            width: "50%", 
            borderStyle: "solid", 
            borderWidth: 0
        },
        tableCol3: { 
            width: "20%", 
            borderStyle: "solid", 
            textAlign: "right",
            borderWidth: 0
        }, 
        tableCell: { 
            margin: "auto",
            fontSize: 15
        },
        stdText: {
            fontSize: 20,
            width: "90%"
        },
        stdImage: {
            width: "50%",
            alignSelf: "center"
        },
        endText: {
            fontSize: 20,
            position: "absolute",
            bottom: "20mm",
            left: "20mm"
        },
        footer: {
            position: "absolute",
            bottom: "10mm",
            left: "10mm",
            fontSize: 10
        },
        pageNumbers: {
            position: 'absolute',
            bottom: "10mm",
            right: "10mm",
            fontSize: 10
        }
    });

    return(
        <Document>
            <Page size="A4" style={styles.page}>   
                <View>
                    <Text style={styles.heading}>Produkter</Text>
                    <Text> </Text>
                </View>            
                <View style={styles.table}>
                    <View fixed style={styles.tableRow}> 
                        <View style={styles.tableCol1}> 
                            <Text style={styles.tableCell}> </Text> 
                        </View> 
                        <View style={styles.tableCol2}> 
                            <Text style={styles.tableCell}> </Text> 
                        </View> 
                        <View style={styles.tableCol3}> 
                            <Text style={styles.tableCell}> </Text> 
                        </View>
                    </View>
                    <View style={styles.tableRow}></View>
                    {products.map((item) => 
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol1}>
                            <Image style={styles.tableImage} src={require('../assets/img/' + item.image)} alt={item.title}/>
                        </View>
                        <View style={styles.tableCol2}>
                            <Text style={styles.tableCell}>{item.title}</Text>
                        </View>
                        <View style={styles.tableCol3}>
                            <Text style={styles.tableCell}>{item.price},-</Text>
                        </View>
                    </View>)}
                </View>
                <Text break />
                <View>
                    <Text style={styles.stdText}>De aller fleste produkter kan jeg også gjøre personlig ved å laser-gravere tekst/bilde:</Text>
                    <Image style={styles.stdImage} src={require('../assets/img/laser.jpg')} alt="" />
                </View>
                <Text break />
                <View>
                    <Text style={styles.stdText}>Noen av produktene jeg har levert tidligere:</Text>
                    <Image style={styles.stdImage} src={require('../assets/img/Klokke1.jpg')} alt="Klokke" />
                    <Image style={styles.stdImage} src={require('../assets/img/Klokke2.jpg')} alt="Klokke" />
                    <Image style={styles.stdImage} src={require('../assets/img/Pizzafjøl.jpg')} alt="Pizzafjøl" />
                    <Image style={styles.stdImage} src={require('../assets/img/Serveringsfat.jpg')} alt="Serveringsfat" />
                    <Image style={styles.stdImage} src={require('../assets/img/toalettpapirstativ.jpg')} alt="Toalettpapirstativ" />
                </View>
                <View style={styles.endText}>
                    <Text> </Text>
                    <Text style={styles.stdText}>Lager også ting på forespørsel.</Text>
                    <Text style={styles.stdText}>Alltid klar for å prøve noe nytt og spennende.</Text>
                    <Text> </Text>
                    <Text style={styles.stdText}>Ta gjerne kontakt:</Text>
                    <Text style={styles.stdText}>Ole Kristian Settem</Text>
                    <Text />
                    <Text style={styles.stdText}>Telefon: 93 41 13 65</Text>
                    <Text style={styles.stdText}>E-post: ole@zmprodukter.no</Text>
                </View>
                <Text fixed style={styles.footer}>zmprodukter.no</Text>
                <Text style={styles.pageNumbers} render={({ pageNumber, totalPages }) => (
                    `${pageNumber} / ${totalPages}`
                    )} fixed />
            </Page>
        </Document>
    )
};

export default PrintToPdf;
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';


function PrintDetailToPdf({ productItem }) {

    const styles = StyleSheet.create({
        page: {
            flexDirection: 'column',
            paddingVertical: "20mm",
            paddingHorizontal: "20mm"
        },
        heading: {
            fontSize: 30,
            margin: "auto",
        },
        stdText: {
            fontSize: 20,
            margin: "auto"
        },
        stdImage: {
            width: "50%",
            alignSelf: "center"
        },
        price: {
            position: "absolute",
            bottom: "20mm",
            alignSelf: "center",
            fontSize: 30
        },
        footer: {
            position: "absolute",
            bottom: "10mm",
            left: "10mm",
            fontSize: 10
        }
    });

    return(
        <Document>
            <Page size="A4" style={styles.page}>   
                <View>
                    <Text style={styles.heading}>{productItem.title}</Text>
                    <Image style={styles.stdImage} src={require('../assets/img/' + productItem.image)} alt={productItem.title}/>
                    <Text> </Text>
                    <Text style={styles.stdText}>Produktnr: {productItem.itemNr}</Text>
                    <Text> </Text>
                    <Text style={styles.stdText}>{productItem.description}</Text>
                    <Text> </Text>
                </View>            
                <Text style={styles.price}>Pris: {productItem.price},-</Text>
                <Text fixed style={styles.footer}>zmprodukter.no</Text>
            </Page>
        </Document>
    )
};

export default PrintDetailToPdf;
import products from '../assets/products.json';

export function readAllProducts() {

    return products.products;
};

export function readGivenProduct(itemNr) {
    
    const productIndex = products.products.findIndex((element) => element.itemNr === itemNr);
    const product = products.products[productIndex];

    return product;
};

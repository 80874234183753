import { PDFDownloadLink } from "@react-pdf/renderer";
import PrintDetailToPdf from "./PrintDetailToPdf";

function ProductDetail({ productItem }) {
    return (
        <div className="product-detail-topdiv">
            <div>
                <h1>{productItem.title}</h1>
                <div className="product-detail-img">
                    <img src={require('../assets/img/' + productItem.image)} alt={productItem.title} />
                </div>
                <div className="product-detail-description">
                    <p>{productItem.description}</p>
                </div>
                <h4>Produktnr: {productItem.itemNr}</h4>
                <h2>Pris: {productItem.price},- NOK</h2>
            </div>
            <PDFDownloadLink document={<PrintDetailToPdf productItem={ productItem }/>} fileName={productItem.title}>
                {({ blob, url, loading, error }) =>
                loading ? 'Klargjør dokumentet...' : 'Last ned dokument'
                }
            </PDFDownloadLink>
        </div>
    )
};

export default ProductDetail;
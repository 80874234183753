import './App.css';
import { useState } from "react";
import ReactModal from "react-modal";
import Modal from "react-modal";
import { MdClose, MdPrint } from 'react-icons/md';

import { PDFViewer } from "@react-pdf/renderer";

import ProductList from './components/ProductList';
import PrintToPdf from './components/PrintToPdf';

function App() {
  Modal.setAppElement('#root');

  const [ modalOpen, setModalOpen ] = useState(false);
  function closeModal() {
    setModalOpen(false);
  }; 
  function openModal() {
    setModalOpen(true);
  }; 

  return (
    <div className="App">
      <button className='md-btn' onClick={openModal}>
        <MdPrint style={{ width: '32px', height: '32px' }} />
      </button>
      <ProductList />
      <ReactModal 
        className='modal'
        ovarlayClassName='modaloverlay'
        presentationStyle='fullScreen'
        isOpen={modalOpen}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
      >
        <button className="md-btn" onClick={closeModal}>
            <MdClose style={{ width: '32px', height: '32px' }} />
        </button>
        <PDFViewer height='1200em' width='100%'>
          <PrintToPdf />
        </PDFViewer>
      </ReactModal>

    </div>
  );
}

export default App;

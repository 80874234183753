import { useState } from "react";
import ReactModal from "react-modal";
import Modal from "react-modal";
import { MdClose } from 'react-icons/md';
import { PDFDownloadLink } from "@react-pdf/renderer";

import PrintAllProducts from "./PrintAllProducts";
import ProductDetail from "./ProductDetail";
import Mailto from "./Mailto";

import { readAllProducts } from "../functions/products"

import './components.css';

function ProductList() {
    Modal.setAppElement('#root');
    const products = readAllProducts();

    const [ modalOpen, setModalOpen ] = useState(false);
    const [ productItem, setProductItem ] = useState();

    function closeModal() {
        setModalOpen(false);
    };
    
    
    function viewProduct(itemNr) {
        setProductItem(itemNr);
        setModalOpen(true);
        console.log(itemNr);
    };

    return (
        <div className="product-list-topdiv">
            <h1>Produkter</h1>
            <table className="product-list-table">
                <thead>
                    <tr>
                        <th></th>
                        <th>Tittel</th>
                        <th>Pris</th>
                    </tr>
                </thead>
                <tbody>
                    {products.map((item) => 
                        <tr key={item.itemNr} onClick={() => viewProduct(item)}>
                            <td className="product-img">
                                <img src={require('../assets/img/' + item.image)} alt={item.title} />
                            </td>
                            <td className="product-title">{item.title}</td>
                            <td className="product-price">kr {item.price},-</td>
                        </tr>)}
                </tbody>
            </table>
            <div className="product-list-laser">
                <h1>De aller fleste produkter kan jeg også gjøre personlig ved å laser-gravere tekst/bilde:</h1>
                <img src={require('../assets/img/laser.jpg')} alt="" />
            </div>
            <div className="product-list-other-products">
                <h1>Noen av produktene jeg har levert tidligere:</h1>
                <img src={require('../assets/img/Klokke1.jpg')} alt="Klokke" />
                <img src={require('../assets/img/Klokke2.jpg')} alt="Klokke" />
                <img src={require('../assets/img/Pizzafjøl.jpg')} alt="Pizzafjøl" />
                <img src={require('../assets/img/Serveringsfat.jpg')} alt="Serveringsfat" />
                <img src={require('../assets/img/toalettpapirstativ.jpg')} alt="Toalettpapirstativ" />
            </div>
            <div>
                <h2>Lager også ting på forespørsel. Alltid klar for å prøve noe nytt og spennende.</h2>
                <h2>Ta gjerne kontakt:</h2>
                <h2>Ole Kristian Settem</h2>
                <h2>Telefon: 93 41 13 65</h2>
                <h2>
                <Mailto email="ole@zmprodukter.no" subject="Forespørsel" body="">
                    Send e-post
                </Mailto>
                </h2>
            </div>
            <PDFDownloadLink document={<PrintAllProducts />} fileName="ZM Produkter">
                {({ blob, url, loading, error }) =>
                loading ? 'Klargjør dokumentet...' : 'Last ned pdf av alle produkter'
                }
            </PDFDownloadLink>
            <ReactModal 
                className='modal'
                ovarlayClassName='modaloverlay'
                presentationStyle='fullScreen'
                isOpen={modalOpen}
                onRequestClose={closeModal}
                shouldCloseOnOverlayClick={true}
                shouldCloseOnEsc={true}
            >
                <button className="md-btn" onClick={closeModal}>
                    <MdClose style={{ width: '32px', height: '32px' }} />
                </button>
                <ProductDetail productItem={productItem} />
            </ReactModal>
        </div>
    )
}

export default ProductList;
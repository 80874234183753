import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import { readAllProducts } from "../functions/products"

function PrintAllProducts() {
    const products = readAllProducts();
    const styles = StyleSheet.create({
        page: {
            flexDirection: 'column',
            paddingVertical: "20mm",
            paddingHorizontal: "20mm"
        },
        top: {
            height: "100%",
        },
        heading: {
            fontSize: 30,
            margin: "auto",
        },
        stdText: {
            fontSize: 20,
            margin: "auto"
        },
        stdImage: {
            width: "50%",
            alignSelf: "center"
        },
        price: {
            position: "absolute",
            bottom: 0,
            alignSelf: "center",
            fontSize: 30
        },
        footer: {
            position: "absolute",
            bottom: "10mm",
            left: "10mm",
            fontSize: 10
        }
    });

    return(
        <Document>
            <Page size="A4" style={styles.page}>   
                {products.map((item) => 
                    <View style={styles.top}>
                    <View>
                        <Text break style={styles.heading}>{item.title}</Text>
                        <Image style={styles.stdImage} src={require('../assets/img/' + item.image)} alt={item.title}/>
                        <Text> </Text>
                        <Text style={styles.stdText}>Produktnr: {item.itemNr}</Text>
                        <Text> </Text>
                        <Text style={styles.stdText}>{item.description}</Text>
                    </View>
                    <Text style={styles.price}>Pris: {item.price},-</Text>
                    </View>
                    )}
                    <Text fixed style={styles.footer}>zmprodukter.no</Text>
            </Page>
        </Document>
    )
};

export default PrintAllProducts;